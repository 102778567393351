<template>
    <div>
        <span>Ant Design of Vue 官方文档写法</span>
        <a-table :columns="columns" :data-source="data">
            <a slot="nametemplate" :rowdata="record" slot-scope="text,record,index" @click="NameClick(record)">{{index}}--{{ record.name }}---------{{text}}</a>
            <span slot="tagstemplate" slot-scope="tags">
                <a-tag v-for="tag in tags"
                       :key="tag">
                    {{ tag.toUpperCase() }}
                </a-tag>
            </span>
        </a-table>
        <br />
        <span>Vue 官方文档写法</span>
        <!-- 推荐使用 -->
        <!-- Vue 官方文档中提到 Ant Design of Vue 官方文档写法已经自 2.6.0 起被废弃-->
        <!-- https://cn.vuejs.org/v2/guide/components-slots.html#废弃了的语法 -->
        <a-table :columns="columns" :data-source="data">
            <template v-slot:nametemplate="text,record,index">
                <a @click="NameClick(record)">{{index}}--{{ record.name }}---------{{text}}</a>
            </template>
            <template v-slot:tagstemplate="tags">
                <span>
                    <a-tag v-for="tag in tags"
                           :key="tag">
                        {{ tag.toUpperCase() }}
                    </a-tag>
                </span>
            </template>
        </a-table>
    </div>
</template>
<script>
    export default {
        name: "TableDemo",
        data() {
            return {
                columns: [
                    {
                        dataIndex: 'name',
                        key: 'name',
                        title: 'name',

                        scopedSlots: { customRender: 'nametemplate' },
                    },
                    {
                        title: 'Age',
                        dataIndex: 'age',
                        key: 'age',
                    },
                    {
                        title: 'Tags',
                        key: 'tags',
                        dataIndex: 'tags',
                        scopedSlots: { customRender: 'tagstemplate' },
                    },
                ],
                data: [
                    {
                        key: '1',
                        name: 'John Brown',
                        age: 32,
                        address: 'New York No. 1 Lake Park',
                        tags: ['nice', 'developer'],
                    },
                    {
                        key: '2',
                        name: 'Jim Green',
                        age: 42,
                        address: 'London No. 1 Lake Park',
                        tags: ['loser'],
                    },
                    {
                        key: '3',
                        name: 'Joe Black',
                        age: 32,
                        address: 'Sidney No. 1 Lake Park',
                        tags: ['cool', 'teacher'],
                    },
                ]
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            NameClick(e) {
                console.log(e);
                alert(e.name);
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>